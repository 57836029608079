<template>
  <base-modal>
    <template #header>
      <h2>Billing</h2>
    </template>
    <template #default>
      <billing-form
        :billing="billing"
        @cancel="$emit('close')"
        @saved="$emit('close')"
      >
        <template #action-btn="{ loading, save, savingBilling }">
          <div class="flex justify-end mt-8 space-x-4">
            <the-button
              @click="$emit('close')"
              :disabled="loading"
              secondary
            >
              Cancel
            </the-button>
            <the-button
              @click="save"
              :disabled="loading || savingBilling"
              primary
            >
              Save
            </the-button>
          </div>
        </template>
      </billing-form>
    </template>
  </base-modal>
</template>

<script>
import BillingForm from '@/components/account/BillingForm.vue'

export default {
  name: 'billing-modify-modal',
  components: { BillingForm },
  props: {
    billing: {
      type: Object,
    },
  },
  modalProps: {
    height: 'auto',
    maxWidth: 900,
    scrollable: true,
  },
}
</script>
