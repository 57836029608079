<template>
  <div v-if="loading">
    <bb-loader />
  </div>
  <table
    v-else
    aria-describedby="Account connected credits card"
    class="w-full"
  >
    <tr>
      <th scope="col">Company name</th>
      <th scope="col">Billing contact</th>
      <th scope="col">Country</th>
      <th scope="col">VAT</th>
      <th scope="col">Action</th>
    </tr>
    <tr v-if="!loading && billings.length === 0">
      <td colspan="4">No billing profile added</td>
    </tr>
    <tr
      v-else
      v-for="billingProfile in billings"
      :key="billingProfile.id"
    >
      <td class="my-auto">{{ billingProfile.company_name }}</td>
      <td class="my-auto">{{ billingProfile.contact_name }}</td>
      <td class="my-auto">{{ billingProfile.country.name }}</td>
      <td class="my-auto">{{ billingProfile.vat }}</td>
      <td class="my-auto">
        <action-button
          @click="showBillingModifyModal(billingProfile)"
          type="manage"
        />
      </td>
    </tr>
    <tr v-if="loading">
      <td colspan="4">
        <bb-loader />
      </td>
    </tr>
  </table>
</template>

<script>
import { mapGetters } from 'vuex'
import BbLoader from '@/components/loader/BBLoader'
import BillingModifyModal from '@/components/modals/BillingModifyModal.vue'
import ActionButton from '@/components/btn/ActionButton'

export default {
  name: 'subscriptions',
  components: { ActionButton, BbLoader },
  computed: {
    ...mapGetters({
      billings: 'billing/getAccountBillings',
      loadingBillings: 'billing/isLoadingAccountBillings',
    }),
    loading() {
      return this.loadingBillings
    },
  },
  methods: {
    showBillingModifyModal(billing) {
      const componentProps = {
        billing: billing,
      }
      this.$modal.show(BillingModifyModal, componentProps, BillingModifyModal.modalProps)
    },
  },
}
</script>

<style lang="scss" scoped>
th {
  text-align: start;
}

tr:nth-child(odd) {
  background-color: #f7f7f7;
}

th,
td {
  @apply py-4 pl-2 border border-bb-pale-grey;
}
</style>
