<template>
  <page>
    <page-section bottom-border>
      <page-title>
        <template #icon>
          <bb-icon
            icon="ic-receipt"
            :size="32"
          />
        </template>
        <template #default> Billing profiles </template>
      </page-title>
    </page-section>
    <page-section bottom-border>
      <account-billing-profiles />
    </page-section>
    <page-section bottom-border>
      <the-button
        @click="showBillingModal"
        :disabled="loadingBillings"
        primary
      >
        Create billing profile
      </the-button>
    </page-section>
  </page>
</template>

<script>
import AccountBillingProfiles from '@/components/account/AccountBillingProfiles'
import { mapGetters } from 'vuex'
import BillingModifyModal from '@/components/modals/BillingModifyModal'
import PageSection from '@/components/base/page/PageSection.vue'
import Page from '@/components/base/page/Page.vue'

export default {
  name: 'Profiles',
  components: { Page, PageSection, AccountBillingProfiles },
  computed: {
    ...mapGetters({
      loadingBillings: 'billing/isLoadingAccountBillings',
    }),
  },
  methods: {
    showBillingModal() {
      this.$modal.show(BillingModifyModal, { parent: 'billing' }, BillingModifyModal.modalProps)
    },
  },
}
</script>

<style scoped></style>
